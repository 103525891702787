.modal {
  align-items: center;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.hide-modal {
  display: none;
}

.modal-content {
  aspect-ratio: 1;
  background-color: white;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
  width: 350px;
}

.close {
  align-items: center;
  aspect-ratio: 1;
  border-radius: 5px;
  display: flex;
  float: right;
  font-size: 25px;
  font-weight: bold;
  justify-content: center;
  width: 30px;
}

.close:hover,
.close:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.modal-details {
  padding: 1rem;
}

.modal-description {
  font-size: 20px;
  font-weight: 300;
}

.modal-options-container {
  align-items: stretch;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  justify-items: stretch;
  margin-top: 1rem;
}

.modal-option {
  aspect-ratio: 1;
  border-radius: 10px;
  border: 3px solid #888888;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0.3;
  padding: 0.4rem;
}

.modal-option-selected {
  border: 3px solid #1a667a;
  opacity: 1;
}

.modal-button {
  background-color: #1a667a;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  margin: 0 auto;
  padding: 0.8rem;
  width: 100%;
  margin-top: 1rem;
}

.modal-button-content {
  font-size: large;
}

.modal-selected-icon {
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid #1a667a;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-bottom: 0.2rem;
  margin: 0;
  padding: 0;
  width: 24px;
}

.modal-option-select {
  font-weight: 'bold';
  font-size: 20px;
}

.modal-option-description {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

// media query for mobile
@media (max-width: 1023px) {
  .modal-content {
    width: 350px;
  }
}
