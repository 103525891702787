.control {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 108px;
  justify-content: space-between;
  align-items: center;
  background-color: #0d0d0d;
}

.control svg {
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 14px;
  fill: white;
  background-color: transparent;
}

.control svg:hover {
  opacity: 0.8;
}

.control:hover {
  opacity: 1;
}

.control-inner-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: flex-start;
  padding-top: 1rem;
  margin: 1rem;
  gap: 0.8rem;
}

.control-inner-container-fixed {
  width: 160px;
}

.tool-box-btn-icon-container {
  background-color: #555b72;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.tool-box-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  width: 80px;
}

.tool-box-button > label {
  color: white;
  font-size: 14px;
  font-weight: 500;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 5px;
}

.tool-box-button-red {
  background-color: #d56250 !important;
  fill: #d56250 !important;
  color: white;
}

.toolbox-button-green {
  background-color: #1a667a !important;
  fill: #1a667a !important;
  color: white;
}

.tool-box-toast-center {
  bottom: 100px;
  width: 500px;
  position: absolute;
  text-align: left;
}

.tool-box-toast-right {
  bottom: 100px;
  right: 2px;
  width: 280px;
  position: absolute;
  text-align: left;
}
