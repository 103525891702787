@media (max-height: 800px) {
  .get-ready-icon {
    width: 250px;
  }
}

.slideshow {
  width: 400px;
  height: 100px;
  color: #1a667a;
  position: relative;
  overflow: hidden;
}

.slideshow p {
  position: absolute;
  text-align: center;
  animation: slide 8s infinite;
}

.slideshow p:nth-child(2) {
  animation-delay: -4.1s;
}

.slideshow p:nth-child(3) {
  animation-delay: -2s;
}

@keyframes slide {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-400px);
    opacity: 1;
  }
  30% {
    transform: translateX(-400px);
    opacity: 0;
  }
  90% {
    transform: translateX(400px);
    opacity: 0;
  }
  95% {
    transform: translateX(400px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
